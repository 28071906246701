.agp {
    &__normalMargin {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &__container {
        &, * {
            box-sizing: border-box;
        }

        img {
            max-width: 100%;
            display: block;    
        }
    }
}