.agp__buttonType {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__buttonContainer {
        margin-right: 22px;
        &:last-child {
            margin-right: 0px;
        }
    }
}