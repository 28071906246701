.agp__checkboxType {
    &__buttonContainer {
        margin-top: 32px;
        text-align: center;
    }

    &__checkboxContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        margin-left: 0.5rem;
        line-height: 1.3;
        font-size: 0.9rem;
        font-style: italic;
    }
}
