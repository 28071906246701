.agp {
    &__wrapper {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100vw;
        z-index: 10000000000000;

        &--note {
            width: auto;
        }
    }

    &__customizerNote {
        cursor: pointer;
        font-size: 12px !important;
    }

    &__container {
        position: relative;
        height: 100vh;
        overflow: scroll;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 32px;
        padding-bottom: 3rem;
    }

    &__background {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__row {
        display: flex;
        position: relative;
        z-index: 1;
        max-width: 600px;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
    }

    &__rowWrapper {
        flex-basis: 0px;
        flex-grow: 1;
        display: flex;
    }

    &__rowInner {
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > * {
            width: 100%;
        }
    }

    &__modalImage {
        flex-basis: 0px;
        flex-grow: 1;
        background-size: cover;
        background-position: center;
        display: none;
        @media screen and (min-width: $m) {
            display: block;
        }
    }

    &__logoContainer {
        margin-bottom: 32px;
    }

    &__logo {
        margin-left: auto;
        margin-right: auto;
    }

    &__heading {
        line-height: 1.1;
        margin-bottom: 32px;
        text-align: center;
    }

    &__message {
        text-align: center;
        line-height: 1.4;
        margin-bottom: 32px;

        &.error ~ * {
            display: none;
        }

        &.success,
        &.fail {
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    &__retryButtonForm {
        text-align: center;
    }

    &__cancel {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        font-size: 2rem;
        transition: all 200ms ease;
        cursor: pointer;
        display: block;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            opacity: 0.6;
        }
    }

    &__disclaimer {
        font-size: 12px;
        text-align: center;
        margin-top: 22px;
        line-height: 1.4;
    }
}